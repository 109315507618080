import {
  ChatDots,
  Gear,
  MoonStars,
  SignOut,
  Sun,
  User,
  UserCircle,
  Users,
} from '@phosphor-icons/react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { useDarkMode } from 'usehooks-ts';

import { Button, IMenuItem, Logo, MenuDropdown } from '@plot/ui';

import { useProfile } from '@/lib/api';
import { Modal, useApp, useAuth, useWorkspace } from '@/lib/contexts';

import WorkspaceMenu from './workspace-menu';

export const Header: React.FC = () => {
  const { workspace } = useWorkspace();
  const profile = useProfile();
  const { isDarkMode, enable, disable } = useDarkMode();
  const auth = useAuth();
  const { dispatch } = useApp();
  const t = useTranslations('Header');

  const DarkModeControlIcon = isDarkMode ? Sun : MoonStars;

  const toggleDarkMode = () => {
    if (isDarkMode) disable();
    else enable();
  };

  const handleFeedBack = () => {
    dispatch({ type: 'openModal', payload: { id: Modal.FEEDBACK } });
  };

  async function handleSignOut() {
    await auth.signOut();
  }

  const menuLinks = [
    {
      href: '/profile',
      label: t('profileLink'),
      icon: <UserCircle size={18} />,
    },
    {
      label: t('feedbackLink'),
      action: handleFeedBack,
      icon: <ChatDots size={18} />,
      closeOnClicked: true,
    },
    {
      label: isDarkMode ? t('lightMode') : t('darkMode'),
      action: toggleDarkMode,
      icon: <DarkModeControlIcon size={18} />,
    },
    { label: t('signOut'), action: handleSignOut, icon: <SignOut size={18} /> },
  ];

  const menuButton = (
    <div className="flex items-center">
      <div className="mr-2 rounded-full bg-neutral-10 p-1.5 dark:bg-neutral-60">
        <User size={20} />
      </div>
      <div
        className="cap-base w-full overflow-hidden text-ellipsis"
        title={profile.data?.fullName}
      >
        {profile.data?.fullName}
      </div>
    </div>
  );

  const itemsMenu = menuLinks.map((link) => ({
    name: link.label,
    icon: link.icon,
    action: link.action,
    to: link.href,
    closeOnClicked: link.closeOnClicked,
  }));

  const renderLink = (item: IMenuItem) => (
    <Link to={item.to || ''} className={clsx('mb-0.5 flex text-sm')}>
      {item.icon && <span className="mr-3">{item.icon}</span>}
      <span className="mr-4">{item.name}</span>
    </Link>
  );

  return (
    <header className="sticky z-20 flex h-[52px] items-center justify-between p-4 shadow-md">
      {/* Left: Logo */}
      <div className="flex items-center">
        <Button variant="none" stretch as={Link} to="/" state={{ back: true }}>
          <Logo />
        </Button>
      </div>

      {/* Right: User Controls */}
      <div className="flex items-center">
        {workspace && (
          <>
            <Button
              variant="none"
              stretch
              as={Link}
              to={`/workspaces/${String(workspace.id)}/members`}
              className="p-2 hover:bg-neutral-10 hover:dark:bg-neutral-60"
            >
              <Users className="cursor-pointer text-xl" size={20} />
            </Button>
            <Button
              variant="none"
              stretch
              as={Link}
              to={`/workspaces/${String(workspace.id)}/settings`}
              className="ml-2 p-2 hover:bg-neutral-10 hover:dark:bg-neutral-60"
            >
              <Gear className="cursor-pointer text-xl" size={20} />
            </Button>
          </>
        )}
        <div className="ml-2 flex cursor-pointer items-center">
          <WorkspaceMenu />
        </div>
        <MenuDropdown
          menuButton={menuButton}
          items={itemsMenu}
          renderLink={renderLink}
        />
      </div>
    </header>
  );
};

export default Header;
