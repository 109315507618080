export const primaryColor = '#4F9B8F';
export const secondaryColor = '#6543DB';

export const colors = {
  primary: {
    DEFAULT: primaryColor,
    20: '#E5F6F5',
    30: '#C0E7E1',
    40: '#A6DBD2',
    45: '#8DCFC4',
    50: '#73C4B5',
    60: '#4F9B8F',
  },
  secondary: {
    DEFAULT: secondaryColor,
    20: '#FFF6F5',
    30: '#E2CBFF',
    40: '#D5B5FF',
    45: '#CAA2FF',
    50: '#A78EFD',
    60: secondaryColor,
  },
  neutral: {
    DEFAULT: '#25222F',
    0: '#FFFFFF',
    10: '#F7F7F7',
    20: '#D6D6D6',
    30: '#A7A7A7',
    40: '#797686',
    50: '#605C6F',
    60: '#43404E',
    70: '#25222F',
    80: '#888888',
    90: '#25222F',
  },
  success: {
    DEFAULT: '#21C980',
    muted: '#88ECBC',
    normal: '#21C980',
    strong: '#19AE6D',
  },
  warning: {
    DEFAULT: '#FFD79D',
    muted: '#FFEBCE',
    normal: '#FFD79D',
    strong: '#FFB74E',
  },
  error: {
    DEFAULT: '#FF6361',
    muted: '#FFB8B7',
    normal: '#FF6361',
    strong: '#FF3331',
  },
  info: {
    DEFAULT: '#8DCFC4',
    muted: '#8DCFC4',
    normal: '#8DCFC4',
    strong: '#4F9B8F',
  },
};
