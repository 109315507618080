import ReactDOM from 'react-dom/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { IntlError, IntlErrorCode, IntlProvider } from 'use-intl';

import 'react-loading-skeleton/dist/skeleton.css';
import * as analytics from '@/lib/analytics/';
import { LoadingProvider, LocaleProvider, useLocale } from '@/lib/contexts';
import { router } from '@/routes';

import '@plot/ui/global.css';
import '@/styles/globals.css';
import { useState } from 'react';
import { useDarkMode } from 'usehooks-ts';

import { Anchor } from '@plot/ui';

import { initializeSentry } from './lib/sentry';
import { colors } from './lib/utils';

analytics.core.init();

const getMessageFallback = ({
  namespace,
  key,
  error,
}: {
  namespace: string;
  key: string;
  error: IntlError;
}) => {
  const path = [namespace, key].filter((part) => part != null).join('.');

  if (namespace === 'GeonorgePlans') {
    const values = key.split('.');

    if (values.length === 2) {
      if (values[0] === 'Property') {
        return values[1];
      }
    }
  }

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return path;
  } else {
    return `Dear developer, please fix this message: ${path}`;
  }
};

const AppContainer: React.FC = () => {
  const { messages, locale } = useLocale();
  const { isDarkMode } = useDarkMode();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 1,
            retryDelay: 1000,
            staleTime: 1000 * 60,
          },
        },
      })
  );

  return (
    <IntlProvider
      defaultTranslationValues={{
        important: (chunks) => <em className="font-bold">{chunks}</em>,
        highlighted: (chunks) => (
          <em className="non-italic font-bold not-italic text-primary dark:text-primary-40">
            {chunks}
          </em>
        ),
        mailToLink: (chunks) => (
          <Anchor variant="primary" href={`mailto:${chunks as string}`}>
            {chunks}
          </Anchor>
        ),
        paragraph: (chunks) => <p>{chunks}</p>,
      }}
      messages={messages}
      locale={locale}
      getMessageFallback={getMessageFallback}
    >
      <LoadingProvider>
        <QueryClientProvider client={queryClient}>
          <SkeletonTheme
            baseColor={`${
              isDarkMode ? colors.neutral[50] : colors.neutral[10]
            }`}
            highlightColor={`${
              isDarkMode ? colors.neutral[60] : colors.neutral[0]
            }`}
          >
            <RouterProvider router={router} />
          </SkeletonTheme>
        </QueryClientProvider>
      </LoadingProvider>
    </IntlProvider>
  );
};

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

initializeSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <LocaleProvider>
    <AppContainer />
  </LocaleProvider>
);
