import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ListboxDropdown } from '@plot/ui';

import { useWorkspaceTracker } from '@/lib/analytics/contexts';
import { useWorkspaces } from '@/lib/api';
import { useWorkspace } from '@/lib/contexts';
import { useSection } from '@/lib/hooks';

export function WorkspaceMenu() {
  const t = useTranslations('Header.WorkspaceMenu');
  const section = useSection();
  const navigate = useNavigate();

  const { workspace: currentWorkspace } = useWorkspace();
  const allWorkspaces = useWorkspaces();

  const tracker = useWorkspaceTracker();

  function getDefaultItem() {
    if (section === 'create') {
      return {
        name: t('createWorkspace'),
        id: '',
      };
    }

    if (currentWorkspace) {
      return {
        name: currentWorkspace.name,
        id: currentWorkspace.id || '',
      };
    }

    return {
      name: t('defaultWorkspace'),
      id: '',
    };
  }

  if (!allWorkspaces.data?.count) return null;

  return (
    <>
      <ListboxDropdown
        buttonClassName="h-10"
        listboxClassName="rounded-lg"
        tooltipContent={t('tooltip')}
        childrenButtonClassName="px-3 py-2"
        maxWidth={200}
        dropdownPosition="left"
        defaultItem={getDefaultItem()}
        items={[
          {
            groupTitle: '',
            items: allWorkspaces.data.results.map((item) => {
              return { name: item.name, id: item.id || '' };
            }),
          },
        ]}
        onClickItem={(item: { id: string }) => {
          tracker?.workspaceSelected(item.id);
          navigate(`/workspaces/${String(item.id)}`);
        }}
      />
      <div className="mx-4 h-7 border-l-1 border-neutral-30  dark:border-neutral-50"></div>
    </>
  );
}

export default WorkspaceMenu;
